import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Service from '../components/services/Service';

import Laser from '../svg/laser.svg';

const MarquageLaser = props => {
  const title = 'Marquage Laser';
  const services = ['Métal', 'Plastique', 'Bois', 'PVC'];
  return (
    <Layout>
      <SEO title={title} description="Marougrav réalise vos projets de gravure au laser sur métal, plastique, bois et PCV." keywords={['gravure']} />
      <Service title={title} services={services} images={props.data.allFile.edges} color="#007A9B">
        <Laser />
      </Service>
    </Layout>
  );
};

export const query = graphql`
  {
    allFile(filter: {extension: {eq: "jpg"}, relativeDirectory: {eq: "laser"}}) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

export default MarquageLaser;
